/* MAIN PAGES */

@media (max-width: 1440px) {

    /*PAGE CONTACT*/

    .contactContainer {
        height: 100vh !;
    }

    
}
@media (max-width: 1024px) {

    /*PAGE CONTACT*/

    .contactContainer {
        height: 1100px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 30px !important;
        top: 100px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 25px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;
        height: 1030px !important;
    }

    .about {
        margin-top: 20px !important;
        margin-bottom: 100px !important;
        font-size: 25px !important;
    }

    .textStyle {
        width: 500px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        margin-top: -100px !important;
        max-width: 50% !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }


}

@media (max-width: 912px) {

    /*PAGE CONTACT*/

    .contactContainer {
        height: 1100px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 30px !important;
        top: 100px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 25px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;
        height: 1030px !important;
    }

    .about {
        margin-top: 20px !important;
        margin-bottom: 60px !important;
        font-size: 20px !important;
        margin-bottom: 150px !important;
    }

    .textStyle {
        width: 500px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        margin-top: -100px !important;
        max-width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 820px) {

    /*PAGE CONTACT*/

    .contactContainer {
        height: 880px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 30px !important;
        top: 100px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 25px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;
        height: 890px !important;
    }

    .about {
        margin-top: 20px !important;
        margin-bottom: 60px !important;
        font-size: 20px !important;
    }

    .textStyle {
        width: 500px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        margin-top: 0px !important;
        max-width: 65% !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 540px) {
    
    /*PAGE CONTACT*/

    .contactContainer {
        height: 760px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 20px !important;
        top: 55px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 15px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;

    }

    .about {
        margin-top: 20px !important;
        margin-bottom: 60px !important;
        font-size: 20px !important;
    }

    .textStyle {
        width: 340px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        margin-top: 0px !important;
        max-width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 430px) {
    
    /*PAGE CONTACT*/

    .contactContainer {
        height: 460px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 20px !important;
        top: 55px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 15px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;

    }

    .about {
        margin-top: 20px !important;
        margin-bottom: 60px !important;
        font-size: 20px !important;
    }

    .textStyle {
        width: 340px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        margin-top: 0px !important;
        max-width: 90% !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 414px) {
    
    /*PAGE CONTACT*/

    .contactContainer {
        height: 460px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 20px !important;
        top: 55px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 15px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;

    }

    .about {
        margin-top: 20px !important;
        margin-bottom: 60px !important;
        font-size: 20px !important;
    }

    .textStyle {
        width: 340px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        margin-top: 0px !important;
        max-width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 412px) {
    
    /*PAGE CONTACT*/

    .contactContainer {
        height: 460px !important;
    }

    .contact {
        position: absolute !important;
        font-size: 20px !important;
        top: 50px !important;

    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 15px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        height: 100vh !important;
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .about {
        margin-top: 1px !important;
        margin-bottom: 60px !important;
        font-size: 20px !important;
    }

    .textStyle {
        width: 300px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .css-1vrhb60 {
        margin-bottom: 0px;
    }

    .imageAbout {
        max-width: 300px !important;
        height: 300px !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 280px) {
    
    /*PAGE CONTACT*/

    .contactContainer {
        height: 460px !important;
        width: 240px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
    }

    .contact {
        position: absolute !important;
        font-size: 20px !important;
        top: 55px !important;
    }

    .email,
    .linkedin,
    .whatsapp {
        font-size: 10px !important;
        font-weight: bold;
    }

    .css-eo2tbg {
        margin-bottom: 0px !important;
    }

    /*PAGE ABOUT*/

    .containerAbout {
        height: auto !important;
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-top: 30px !important;
        padding-bottom: 150px !important;
    }

    .textStyle {
        width: 250px !important;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px !important;
    }

    .imageAbout {
        max-width: 90% !important;
    }


}

/* MAIN PAGES */


/*SERVICE PAGES*/

@media (max-width: 1024px) {

    .containerLaudoPpci {
        height: 1240px !important;
    }

    .containerAr {
        height: 1170px !important;
    }

    .containerSpda {
        height: 1180px !important;
    }

    .containerEnergiaSolar {
        height: 1150px !important;
    }

    .containerAlvara {
        height: 1180px !important;
    }

    .containerPrevencao {
        height: 1310px !important;
    }

    .containerPmoc {
        height: 1280px !important;
    }

    .containerProjetoEletrico {
        height: 1120px !important;
    }

    .containerLaudoMaquinas {
        height: 1255px !important;
    }

    #text_style {
        flex-direction: column;
    }

    .container_text {
        display: flex !important;
        flex-direction: column;
        width: 600px !important;
        height: 400px !important;
    }

    .div_banner {
        height: auto !important;
    }

    h1 {
        width: 600px !important;
        font-size: 15px !important;
        height: 0px;
    }

    h5 {
        width: auto !important;
        font-size: 12px !important;
    }

    p {
        width: auto !important;
        font-size: 12px !important;
    }

    .paragraph {
        font-size: 8px !important;
    }


}

@media (max-width: 912px) {

    .containerLaudoPpci {
        height: 1280px !important;
    }

    .containerAr {
        height: 1205px !important;
    }

    .containerSpda {
        height: 1213px !important;
    }

    .containerEnergiaSolar {
        height: 1180px !important;
    }

    .containerAlvara {
        height: 1205px !important;
    }

    .containerPrevencao {
        height: 1340px !important;
    }

    .containerPmoc {
        height: 1310px !important;
    }

    .containerProjetoEletrico {
        height: 1150px !important;
    }

    .containerLaudoMaquinas {
        height: 1290px !important;
    }

    #text_style {
        flex-direction: column;
    }

    .container_text {
        display: flex !important;
        flex-direction: column;
        width: 600px !important;
        height: 400px !important;
    }

    .div_banner {
        height: auto !important;
    }

    h1 {
        width: 600px !important;
        font-size: 15px !important;
        height: 0px;
    }

    h5 {
        width: auto !important;
        font-size: 12px !important;
    }

    p {
        width: auto !important;
        font-size: 12px !important;
    }

    .paragraph {
        font-size: 8px !important;
    }


}

@media (max-width: 820px) {

    .containerLaudoPpci {
        height: 1200px !important;
    }

    .containerAr {
        height: 1205px !important;
    }

    .containerSpda {
        height: 1215px !important;
    }

    .containerEnergiaSolar {
        height: 1180px !important;
    }

    .containerAlvara {
        height: 1210px !important;
    }

    .containerPrevencao {
        height: 1340px !important;
    }

    .containerPmoc {
        height: 1310px !important;
    }

    .containerProjetoEletrico {
        height: 1155px !important;
    }

    .containerLaudoMaquinas {
        height: 1290px !important;
    }

    #text_style {
        flex-direction: column;
    }

    .container_text {
        display: flex !important;
        flex-direction: column;
        width: 600px !important;
        height: 400px !important;
    }

    .div_banner {
        height: auto !important;
    }

    h1 {
        width: 600px !important;
        font-size: 15px !important;
        height: 0px;
    }

    h5 {
        width: auto !important;
        font-size: 12px !important;
    }

    p {
        width: auto !important;
        font-size: 12px !important;
    }


    .paragraph {
        font-size: 10px !important;
    }
}

@media (max-width: 768px) {

    .containerLaudoPpci {
        height: 1250px !important;
    }

    .containerAr {
        height: 1170px !important;
    }

    .containerSpda {
        height: 1160px !important;
    }

    .containerSpda {
        height: 1180px !important;
    }

    .containerEnergiaSolar {
        height: 1150px !important;
    }

    .containerAlvara {
        height: 1180px !important;
    }

    .containerPrevencao {
        height: 1310px !important;
    }

    .containerPmoc {
        height: 1280px !important;
    }

    .containerProjetoEletrico {
        height: 1120px !important;
    }

    .containerLaudoMaquinas {
        height: 1260px !important;
    }

    #text_style {
        flex-direction: column;
    }

    .container_text {
        display: flex !important;
        flex-direction: column;
        width: 600px !important;
        height: 400px !important;
    }

    .div_banner {
        height: auto !important;
    }

    h1 {
        width: 600px !important;
        font-size: 15px !important;
        height: 0px;
    }

    h5 {
        width: auto !important;
        font-size: 12px !important;
    }

    p {
        width: auto !important;
        font-size: 12px !important;
    }


    .paragraph {
        font-size: 10px !important;
    }
}

@media (max-width: 540px) {

    .containerLaudoPpci {
        height: 1450px !important;
    }

    .containerAr {
        height: 1350px !important;
    }

    .containerSpda {
        height: 1300px !important;
    }

    .containerEnergiaSolar {
        height: 1275px !important;
    }

    .containerAlvara {
        height: 1300px !important;
    }

    .containerPrevencao {
        height: 1540px !important;
    }

    .containerPmoc {
        height: 1430px !important;
    }

    .containerProjetoEletrico {
        height: 1280px !important;
    }

    .containerLaudoMaquinas {
        height: 1490px !important;
    }

    #text_style {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container_text {
        display: flex !important;
        flex-direction: column;
        width: 400px !important;
        height: 400px !important;
    }

    .div_banner {
        height: auto !important;
    }

    h1 {
        width: 314px !important;
        font-size: 15px !important;
        height: 0px;
    }

    h5 {
        width: auto !important;
        font-size: 12px !important;
    }

    p {
        width: auto !important;
        font-size: 12px !important;
    }
}

@media (max-width: 430px) {
    .BannerImage {
        height: 200px !important;
    }

    .containerLaudoPpci {
        height: 1380px !important;
    }

    .containerAr {
        height: 1190px !important;
    }

    .containerSpda {
        height: 1160px !important;
    }

    .containerEnergiaSolar {
        height: 1150px !important;
    }

    .containerAlvara {
        height: 1160px !important;
    }

    .containerPrevencao {
        height: 1480px !important;
    }

    .containerPmoc {
        height: 1340px !important;
    }

    .containerProjetoEletrico {
        height: 1090px !important;
    }

    .containerLaudoMaquinas {
        height: 1365px !important;
    }

    #text_style {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container_text {
        display: flex !important;
        flex-direction: column;
        width: 300px !important;
        height: 400px !important;
    }

    .div_banner {
        height: auto !important;
    }

    h1 {
        width: 314px !important;
        font-size: 15px !important;
        height: 0px;
    }

    h5 {
        width: auto !important;
        font-size: 12px !important;
        text-align: justify;
    }

    p {
        width: auto !important;
        font-size: 12px !important;
        text-align: justify;
    }

    .paragraph {
        font-size: 10px !important;
    }
}

/*SERVICE PAGES*/