@media (max-width: 1024px) {
    .section-tree {
        height: 2040px !important;
        padding: 60px 0px 120px 0px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }
}

@media (max-width: 912px) {
    .section-tree {
        height: 2050px !important;
        padding-bottom: 150px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 820px) {
    .section-tree {
        height: 2050px !important;
        padding-bottom: 150px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .section-tree {
        display: flex !important;
        height: 2000px !important;
        padding-bottom: 120px !important;
        flex-direction: column !important;
        justify-content: space-around !important;
    }

    .divtitle {
        top: 50px !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 430px) {
    .section-tree {
        height: 1910px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsTree {
        width: 320px !important;
        height: auto !important;
    }

    .cardEletrico {
        margin-bottom: 50px;
    }

    .cardSolar {
        margin-bottom: 40px;
    }

    .cardSpda {
        margin-bottom: 40px;
    }
}

@media (max-width: 414px) {
    .section-tree {
        height: 1912px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsTree {
        width: 320px !important;
        height: auto !important;
    }

    .cardEletrico {
        margin-bottom: 50px;
    }

    .cardSolar {
        margin-bottom: 40px;
    }

    .cardSpda {
        margin-bottom: 40px;
    }
}

@media (max-width: 390px) {
    .section-tree {
        height: 1912px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsTree {
        width: 320px !important;
        height: auto !important;
    }

    .cardEletrico {
        margin-bottom: 50px;
    }

    .cardSolar {
        margin-bottom: 40px;
    }

    .cardSpda {
        margin-bottom: 40px;
    }
}

@media (max-width: 280px) {
    .section-tree {
        height: 1970px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsTree {
        width: 250px !important;
        height: auto !important;
    }

    .cardEletrico {
        margin-bottom: 50px;
    }

    .cardSolar {
        margin-bottom: 40px;
    }

    .cardSpda {
        margin-bottom: 40px;
    }
}