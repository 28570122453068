@media (max-width: 1024px) {
    .section-four {
        height: 2030px !important;
        padding: 60px 0px 120px 0px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        justify-content: space-around !important;
    }
}

@media (max-width: 912px) {
    .section-four {
        height: 2050px !important;
        padding-bottom: 150px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 820px) {
    .section-four {
        height: 2040px !important;
        padding: 60px 0px 150px 0px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        justify-content: space-around !important;
    }
}

@media (max-width: 768px) {
    .section-four {
        display: flex !important;
        height: 2000px !important;
        padding-bottom: 120px !important;
        flex-direction: column !important;
        justify-content: space-around !important;
        align-items: center !important;
    }

    .divtitle {
        top: 50px !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 430px) {
    .section-four {
        height: 2000px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsFour {
        height: auto !important;
        width: 320px !important;
    }

    .cardAr {
        margin-bottom: 40px;
    }

    .cardPpci {
        margin-bottom: 40px;
    }

    .cardMaquinas {
        margin-bottom: 40px;
    }
}

@media (max-width: 414px) {
    .section-four {
        height: 2000px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsFour {
        height: auto !important;
        width: 320px !important;
    }

    .cardAr {
        margin-bottom: 40px;
    }

    .cardPpci {
        margin-bottom: 40px;
    }

    .cardMaquinas {
        margin-bottom: 40px;
    }
}

@media (max-width: 390px) {
    .section-four {
        height: 2002px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsFour {
        height: auto !important;
        width: 320px !important;
    }

    .cardAr {
        margin-bottom: 40px;
    }

    .cardPpci {
        margin-bottom: 40px;
    }

    .cardMaquinas {
        margin-bottom: 40px;
    }
}

@media (max-width: 280px) {
    .section-four {
        height: 2190px !important;
        padding-bottom: 140px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsFour {
        height: auto !important;
        width: 250px !important;
    }

    .cardAr {
        margin-bottom: 40px;
    }

    .cardPpci {
        margin-bottom: 40px;
    }

    .cardMaquinas {
        margin-bottom: 40px;
    }
}