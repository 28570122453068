@media (max-width: 1024px) {
    .section-two {
        height: 2040px !important;
        padding: 60px 0px 120px 0px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 912px) {
    .section-two {
        height: 2050px !important;
        padding-bottom: 150px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 820px) {
    .section-two {
        height: 2050px !important;
        padding-bottom: 150px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .section-two {
        height: 2000px !important;
        padding-bottom: 120px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        justify-content: space-around !important;
    }

    .title {
        padding: -10px !important;
        position: absolute;
        width: 500px;
        top: -30px;
        text-align: center;
    }
}

@media (max-width: 430px) {
    .section-two {
        height: 1940px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsTwo {
        width: 320px !important;
        height: auto !important;
    }

    .cardPmoc {
        margin-bottom: 50px;
    }

    .cardPrevencao {
        margin-bottom: 50px;
    }

    .cardAlvara {
        margin-bottom: 40px;
    }
}

@media (max-width: 414px) {
    .section-two {
        height: 1945px !important;
        padding-bottom: 120px !important;
    }

    .title {
        width: 300px !important;
    }

    .cardsTwo {
        width: 320px !important;
        height: auto !important;
    }

    .cardPmoc {
        margin-bottom: 50px;
    }

    .cardPrevencao {
        margin-bottom: 50px;
    }

    .cardAlvara {
        margin-bottom: 40px;
    }
}

@media (max-width: 390px) {
    .section-two {
        height: 1940px !important;
        padding-bottom: 120px !important;
    }

    .cardsTwo {
        width: 320px !important;
        height: auto !important;
    }

    .cardPmoc {
        margin-bottom: 50px;
    }

    .cardPrevencao {
        margin-bottom: 50px;
    }

    .cardAlvara {
        margin-bottom: 40px;
    }

    .divtitle {
        width: 50px !important;
    }

    .title {
        width: 300px !important;
    }
}

@media (max-width: 280px) {
    .section-two {
        height: 2045px !important;
    }

    .cardsTwo {
        width: 250px !important;
        height: auto !important;
    }

    .cardPmoc {
        margin-bottom: 50px;
    }

    .cardPrevencao {
        margin-bottom: 50px;
    }

    .cardAlvara {
        margin-bottom: 40px;
    }

    .divtitle {
        width: 50px !important;
    }

    .title {
        width: 300px !important;
    }
}