* {
  margin: 0;
  padding: 0;
}

#custom-nav-link {
  color: white;
  text-decoration: none;
  position: relative;
}

#custom-nav-link:hover {
  color: rgb(214, 193, 193);
}

#basic-nav-dropdown {
  color: white;
}

#basic-nav-dropdown:hover {
  color: rgb(214, 193, 193);
}

#contend {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-align: justify;
  background: white;
}

img {
  display: block;
  margin: 0;
  padding: 0;
}

@media (max-width: 912px) {
  .socials {
    padding: 10px 0px;
  }
}

@media (max-width: 768px) {
  .paragraph {
    font-size: 8px !important;
  }

  .socials {
    padding: 10px 0px;
  }
}

@media (max-width: 820px) {
  .socials {
    padding: 10px 0px;
  }
}

@media (max-width: 768px) {
  .paragraph {
    font-size: 8px !important;
  }

  .socials {
    margin-left: 10px;
    padding: 10px 0px;
  }
}

@media (max-width: 768px) {
  .paragraph {
    font-size: 8px !important;
  }

  #custom-nav-link,
  #basic-nav-dropdown {
    margin-left: 10px;
  }

  .socials {
    margin-left: 10px;
    padding: 10px 0px;
  }
}

@media (max-width: 430px) {
  .paragraph {
    font-size: 8px !important;
  }

  #custom-nav-link,
  #basic-nav-dropdown {
    margin-left: 10px;
  }

  .socials {
    margin-left: 10px;
    padding: 10px 0px;
  }
}

@media (max-width: 280px) {
  .paragraph {
    width: 50px !important;
  }

  .animated-button1 {
    width: 250px !important;
    font-size: 13px !important;
    bottom: -33px !important;
    left: 128px !important;
  }

  .textStyle {
    width: 150px !important;
  }

  .navbar-toggler {
    margin: 8px 0px;
    height: 30px !important;
    padding: 0px !important;
  }

  .navbar-toggler-icon {
    height: 1px;
  }
}