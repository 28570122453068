@media (min-width: 1440px) {
    .carousel {
        height: auto !important;
    }

    .carousel.slide {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 75px !important;
    }

    .carousel_caption_second {
        top: 20px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
        padding-bottom: 20px !important;
    }

    .h1Second_slide {
      
        padding-bottom: 0px !important;
    }
    .pSecond_slide {
        text-align: center;
        font-size: 15px !important;
        padding-bottom: 50px !important;
    }

    .h1First_slide {
        padding-top: 15px !important;
    }

    .h1Third_slide {
        padding-top: 60px !important;
    }
}

@media ((min-width: 1025px) and (max-width: 1280px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 75px !important;
    }

    .carousel_caption_second {
        top: 20px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
        padding-bottom: 20px !important;
    }

    .h1Second_slide {
      
        padding-bottom: 0px !important;
    }
    .pSecond_slide {
        text-align: center;
        font-size: 15px !important;
        padding-bottom: 50px !important;
    }

    .h1First_slide {
        padding-top: 15px !important;
    }

    .h1Third_slide {
        padding-top: 60px !important;
    }
}

@media ((min-width: 821px) and (max-width: 1024px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 75px !important;
    }

    .carousel_caption_second {
        top: 20px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
        padding-bottom: 20px !important;
    }

    .h1First_slide {
        font-size: 40px !important;
        padding-bottom: 30px !important;
    }

    .h1Second_slide {
        font-size: 40px !important;
        padding-bottom: 40px !important;
    }

    .h1Third_slide {
        font-size: 40px !important;
        padding-bottom: 40px !important;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 15px !important;
        padding-bottom: 20px;
    }
}

@media ((min-width: 769px) and (max-width: 820px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 78px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1First_slide {
        font-size: 40px !important;
        padding-bottom: 30px !important;
    }

    .h1Second_slide {
        font-size: 40px !important;
        padding-bottom: 40px !important;
    }

    .h1Third_slide {
        font-size: 40px !important;
        padding-bottom: 40px !important;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 15px !important;
        padding-bottom: 20px;
    }
}

@media ((min-width: 541px) and (max-width: 768px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 75px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1First_slide {
        font-size: 40px !important;
        padding-bottom: 30px !important;
    }

    .h1Second_slide {
        font-size: 40px !important;
        padding-bottom: 40px !important;
    }

    .h1Third_slide {
        font-size: 40px !important;
        padding-bottom: 40px !important;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 15px !important;
        padding-bottom: 20px;
    }
}

@media ((min-width: 431px) and (max-width: 540px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 75px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1First_slide {
        font-size: 23px !important;
        padding-bottom: 5px !important;
    }

    .h1Second_slide {
        font-size: 23px !important;
        padding-bottom: 20px !important;
        padding-top: 18px !important;
    }
    
    .pSecond_slide {
        text-align: center;
        font-size: 10px !important;
        padding-bottom: 20px;
    }
    .h1Third_slide {
        font-size: 23px !important;
        padding-bottom: 15px !important;
    }

}

@media ((min-width: 415px) and (max-width: 430px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 80px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1Second_slide {
        padding-bottom: 12px;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 8px !important;
    }
}

@media ((min-width: 391px) and (max-width: 414px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 80px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1Second_slide {
        padding-bottom: 12px;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 8px !important;
    }
}

@media ((min-width: 376px) and (max-width: 390px)) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 80px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1Second_slide {
        padding-bottom: 12px;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 8px !important;
    }
}

@media (max-width: 375px) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 80px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1Second_slide {
        padding-bottom: 12px;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 8px !important;
    }
}

@media (max-width: 280px) {
    .carousel {
        height: auto !important;
    }

    .carousel_caption_First {
        padding-top: 80px !important;
    }

    .carousel_caption_second {
        top: 10px !important;
        padding-bottom: 5px;
    }

    .carousel_caption_third {
        top: 0px !important;
    }

    .h1Second_slide {
        padding-bottom: 12px;
    }

    .pSecond_slide {
        text-align: center;
        font-size: 8px !important;
    }
}